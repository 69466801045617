$(function () {
    // Cached selectors
    const $screenSelectListing = $('.screen-select-listing');
    const $startInput = $('#start');
    const $stopInput = $('#stop');
    const $screenSelectSubmit = $('.screen-select-submit');
    const $campaignFile = $('#campaign_file_new_campaign');
    const $importCampaignButton = $('#import_campaign_button');
    const $ssfForm = $('#ssf');
    const locale = 'cs-CZ';

    // Utility functions
    function parseDate(dateStr) {
        const parts = dateStr.trim().split('.');
        if (parts.length !== 3) return null;
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const year = parseInt(parts[2], 10);
        const date = new Date(year, month, day);
        return isNaN(date.getTime()) ? null : date;
    }

    function formatDate(date) {
        if (!date) return '';
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }

    function updatePostData() {
        const data = $ssfForm.serializeArray().filter(item => item.name !== '_method');
        $screenSelectListing.data('postData', data);
    }

    function toggleImportButton() {
        const hasFile = $campaignFile.val() !== '';
        $importCampaignButton.prop('disabled', !hasFile);
    }

    // Setup import button
    toggleImportButton();
    $campaignFile.on('change', toggleImportButton);

    // Top form: Start date change
    $startInput.on('change', function () {
        const value = this.value;
        $('.start').val(value);

        const startDate = parseDate(value);
        const stopDate = parseDate($stopInput.val());

        if (!stopDate || stopDate < startDate) {
            $stopInput.val(value);
            $('.stop').val(value);
        }

        updatePostData();
        $screenSelectListing.trigger('refresh');
    });
    if ($startInput.val()) {
        $('.start').val($startInput.val());
    }

    // Top form: Stop date change
    $stopInput.on('change', function () {
        const value = this.value;
        $('.stop').val(value);

        const startDate = parseDate($startInput.val());
        const stopDate = parseDate(value);

        if (!startDate || stopDate < startDate) {
            $startInput.val(value);
            $('.start').val(value);
        }

        updatePostData();
        $screenSelectListing.trigger('refresh');
    });
    if ($stopInput.val()) {
        $('.stop').val($stopInput.val());
    }

    // #slots change – adjust each screen’s select to not exceed available options
    $('#slots').on('change', function () {
        const selectedValue = parseInt($(this).val(), 10);
        $('.slots').each(function () {
            const maxOptions = $(this).find('option').length;
            if (selectedValue > maxOptions) {
                $(this).val(maxOptions);
            } else {
                $(this).val(selectedValue);
            }
        });
        updatePostData();
        updateAllAvailableSlotsCount();
        updateAllChosenSlotsCount();
        $screenSelectListing.trigger('refresh');
    });

    // Discount change
    $('#discount').on('change', function () {
        const value = $(this).val();
        $('.discount').val(value);
        updatePostData();
    });

    // Count update functions
    function updateScreenCount() {
        const $visibleListingItems = $('tbody .listing-item:not(.d-none)');
        const screenCount = $visibleListingItems.length;
        $('.num-all-screens').text(screenCount.toLocaleString(locale));

        const selectedScreenCount = $visibleListingItems.find('.screen-select-checkbox:checked').length;
        $('.num-selected-screens').text(selectedScreenCount.toLocaleString(locale));
    }

    function parseDateToIndex(dateStr) {
        const startDate = parseDate($startInput.val());
        const stopDate = parseDate($stopInput.val());
        const date = parseDate(dateStr);

        if (!startDate || !stopDate || !date) return null;
        if (date < startDate) return -1;
        return Math.floor((date - startDate) / (24 * 3600 * 1000));
    }

    function getClipsFromDs(dsElement, maxSlots) {
        const parts = dsElement.textContent.trim().split(',');
        const clipsPerSlot = parseInt(parts[0], 10) || 0;
        const slotsAvailable = parseInt(parts[1], 10) || 0;
        return clipsPerSlot * Math.min(slotsAvailable, maxSlots);
    }

    const updateAllAvailableSlotsCount = debounce(function () {
        let totalForAllScreens = 0;
        let isIncomplete = false;

        const startIndex = parseDateToIndex($startInput.val());
        const stopIndex = parseDateToIndex($stopInput.val());

        if (startIndex === null || stopIndex === null) {
            $('.num-all-spots').text('0');
            $('.num-all-spots-plus').toggle(false);
            return;
        }

        const $visibleListingItems = $('tr.listing-item:not(.d-none)');

        $visibleListingItems.each(function () {
            const $listingItem = $(this);
            const $select = $listingItem.find('select.slots');
            let slotsFromInput;
            if ($select.length) {
                slotsFromInput = parseInt($select.val(), 10) || 0;
            } else {
                slotsFromInput = parseInt($listingItem.find('.slots').val(), 10) || 0;
            }
            const $nextNoTopBorder = $listingItem.next('.no-top-border');
            if ($nextNoTopBorder.length === 0) return;
            const $dsElements = $nextNoTopBorder.find('.ds');

            for (let i = startIndex; i <= stopIndex; i++) {
                const dsElement = $dsElements.eq(i)[0];
                if (dsElement) {
                    totalForAllScreens += getClipsFromDs(dsElement, slotsFromInput);
                } else {
                    isIncomplete = true;
                }
            }
        });

        $('.num-all-spots').text(totalForAllScreens.toLocaleString(locale));
        $('.num-all-spots-plus').toggle(isIncomplete);
    }, 200);

    // ---- IMPORTANT: This update now checks if a screen’s select is empty.
    const updateAllChosenSlotsCount = debounce(function () {
        let totalForAllScreens = 0;
        let isIncomplete = false;
        let problematicScreens = [];

        const $selectedListingItems = $('tr.listing-item').has('.screen-select-checkbox:checked');

        $selectedListingItems.each(function () {
            const $listingItem = $(this);
            // If this screen uses a select.slots and it has no options, mark it as problematic.
            const $select = $listingItem.find('select.slots');
            if ($select.length && $select.find('option').length === 0) {
                const screenNumber = $listingItem.find('.screen-tooltip').text().trim();
                problematicScreens.push(screenNumber);
                return; // Skip calculations for this row.
            }
            const startDateIndex = parseDateToIndex($listingItem.find('.start.date').val());
            const stopDateIndex = parseDateToIndex($listingItem.find('.stop.date').val());
            if (startDateIndex === null || stopDateIndex === null) return;

            // Use the select value if available; otherwise, fall back to the .slots element.
            let slotsFromInput = $select.length
                ? (parseInt($select.val(), 10) || 0)
                : (parseInt($listingItem.find('.slots').val(), 10) || 0);

            const $nextNoTopBorder = $listingItem.next('.no-top-border');
            if ($nextNoTopBorder.length === 0) return;
            const $dsElements = $nextNoTopBorder.find('.ds');

            for (let i = startDateIndex; i <= stopDateIndex; i++) {
                const dsElement = $dsElements.eq(i)[0];
                if (dsElement) {
                    totalForAllScreens += getClipsFromDs(dsElement, slotsFromInput);
                } else {
                    isIncomplete = true;
                }
            }
        });

        $('.num-selected-spots').text(totalForAllScreens.toLocaleString(locale));
        $('.num-selected-spots-plus').toggle(isIncomplete);

        if (problematicScreens.length > 0) {
            showConfigurationWarning(problematicScreens, true);
        } else {
            hideConfigurationWarning(true);
        }
    }, 200);

    // When dates change in listing or showing rows
    $(document).on('change', '.start.date, .stop.date, .showing-start.date, .showing-stop.date', function () {
        const $changedInput = $(this);
        const $parent = $changedInput.closest('.listing-item, .showing-row');
        let $startInputElem, $stopInputElem;

        // Determine whether we are dealing with index screens or showings
        if ($changedInput.hasClass('start') || $changedInput.hasClass('showing-start')) {
            $startInputElem = $changedInput;
            $stopInputElem = $parent.find('.stop.date, .showing-stop.date');
        } else {
            $stopInputElem = $changedInput;
            $startInputElem = $parent.find('.start.date, .showing-start.date');
        }

        const startDate = parseDate($startInputElem.val());
        const stopDate = parseDate($stopInputElem.val());

        if ($changedInput.is($startInputElem)) {
            if (!stopDate || stopDate < startDate) {
                $stopInputElem.val(formatDate(startDate));
            }
        } else {
            if (!startDate || startDate > stopDate) {
                $startInputElem.val(formatDate(stopDate));
            }
        }
    });

    // Listing events and UI updates
    $screenSelectListing.on('load.screen-select update.listpress', function () {
        updateScreenCount();
        updateAllAvailableSlotsCount();
        updateAllChosenSlotsCount();

        // Bind date change for each screen row
        $('.start.date, .stop.date').off('change').on('change', function () {
            updateAllChosenSlotsCount();
        });

        // "Select all" checkbox
        $('.screen-select-checkbox-all').off('click').on('click', function () {
            const isChecked = this.checked;
            $('.screen-select-checkbox:visible').prop('checked', isChecked);
            $screenSelectSubmit.prop('disabled', $('.screen-select-checkbox:checked').length < 1);
            updateScreenCount();
            updateAllAvailableSlotsCount();
            updateAllChosenSlotsCount();
        });

        // Individual screen checkbox updates
        $('.screen-select-checkbox')
            .off('click.update.screen-select')
            .on('click.update.screen-select', function () {
                $screenSelectSubmit.prop('disabled', $('.screen-select-checkbox:checked').length < 1);
                updateScreenCount();
                updateAllChosenSlotsCount();
            })
            .trigger('update.screen-select');

        // Toggle exact time inputs
        $('.exact-time')
            .on('click update.screen-select', function () {
                $(this).closest('tr').find('.slots').toggle(!this.checked);
                $(this).closest('tr').find('.time').toggle(this.checked);
                if (!this.checked) $(this).closest('tr').find('.time').val('');
            })
            .trigger('update.screen-select');

        // Update post data and recalc counts on any change
        $screenSelectListing.find('select, input')
            .off('change')
            .on('change', function () {
                updatePostData();
                updateAllAvailableSlotsCount();
                updateAllChosenSlotsCount();
            });
    }).trigger('load.screen-select');

    updatePostData();

    // ========= SCREEN CONFIGURATION UPDATES =========

    // When a screen’s start or stop date changes on the listing page,
    // send an AJAX request to update configuration options.
    $(document).on('change', '.listing-item .start, .listing-item .stop', function () {
        const $parent = $(this).closest('.listing-item');
        const screenId = $parent.data('id');
        const screenNo = $parent.find('.screen-tooltip').text().trim();
        const startDate = $parent.find('.start').val();
        const stopDate = $parent.find('.stop').val();

        updateSlotsForScreen($parent, screenId, screenNo, startDate, stopDate, true);
    });

    // For showings – do the same and then check for configuration conflicts.
    $(document).on('change', '.showing-row input[id$="_start"], .showing-row input[id$="_stop"]', function () {
        const $parent = $(this).closest('.showing-row');
        const screenId = $parent.find('input[id$="_screen_id"]').val();
        const screenNo = $parent.find('td[data-column="no"]').text().trim();
        const startDate = $parent.find('input[id$="_start"]').val();
        const stopDate = $parent.find('input[id$="_stop"]').val();

        updateSlotsForScreen($parent, screenId, screenNo, startDate, stopDate, false);

        setTimeout(function () {
            const problematicShowings = checkShowingSelects();
            if (problematicShowings.length > 0) {
                showConfigurationWarning(problematicShowings, false);
            } else {
                hideConfigurationWarning(false);
            }
        }, 200);
    });

    const updateSlotsForScreen = function ($parent, screenId, screenNo, startDate, stopDate, is_index) {
        const isValidDate = (dateString) => {
            const dateParts = dateString.replace(/\s+/g, '').match(/(\d{2})\.(\d{2})\.(\d{4})/);
            if (!dateParts) return false;
            const formattedDate = `${dateParts[3]}-${dateParts[2]}-${dateParts[1]}`;
            const date = Date.parse(formattedDate);
            return !isNaN(date);
        };

        if (isValidDate(startDate) && isValidDate(stopDate)) {
            $.ajax({
                url: `/screens/${screenId}/screen_config`,
                method: 'GET',
                data: { start: startDate, stop: stopDate },
                success: function (res) {
                    $(`#config_change_${screenNo}`).remove();

                    if (res.options) {
                        if (is_index) {
                            $parent.find('.slots').html(res.options);
                        } else {
                            $parent.find('select[data-target="showings-mass-change.slotsShowingInput"]').html(res.options);
                        }
                    }

                    if (res.change_dates) {
                        if (is_index) {
                            $parent.find('.slots').empty();
                        } else {
                            $parent.find('select[data-target="showings-mass-change.slotsShowingInput"]').empty();
                        }
                        const date_changes = `<input type="hidden" name="config_change_${screenNo}" value="${screenNo}:${res.change_dates.join(
                            ', '
                        )}" id="config_change_${screenNo}" />`;
                        $parent.append(date_changes);
                    }

                    if (is_index) {
                        updateAllChosenSlotsCount();
                    }
                },
                error: function () {
                    console.log('Error retrieving screen configuration');
                }
            });
        } else {
            console.log('Invalid dates, no request sent.');
        }
    };

    function showConfigurationWarning(screenNos, is_index) {
        const $warningDiv = $('#configWarning');
        const $warningScreenList = $warningDiv.find('.warning-screen-list');
        $warningScreenList.empty();

        screenNos.forEach(function (screenNo) {
            const $configChangeDates = $(`#config_change_${screenNo}`);
            if ($configChangeDates.length) {
                const configChangeData = $configChangeDates.val();
                const [screen, configChanges] = configChangeData.split(':');
                $warningScreenList.append(
                    `<li>Na obrazovce ${screen} probíhá změna prodejního modelu k datu ${configChanges.replace(
                        /\s+/g,
                        ''
                    )}.</li>`
                );
            }
        });

        if (is_index) {
            $('.screen-select-submit').prop('disabled', true);
        } else {
            $('input[type="submit"][value="Uložit změny"]').prop('disabled', true);
            $('a[data-method="put"]').addClass('disabled');
        }
        $warningDiv.removeClass('d-none');
    }

    function hideConfigurationWarning(is_index) {
        if (is_index) {
            $('.screen-select-submit').prop('disabled', false);
        } else {
            $('input[type="submit"][value="Uložit změny"]').prop('disabled', false);
            $('a[data-method="put"]').removeClass('disabled');
        }
        $('#configWarning').addClass('d-none');
    }

    function checkShowingSelects() {
        const problematicScreens = [];
        $('.showing-row').each(function () {
            const screenNo = $(this).find('td[data-column="no"]').text().trim();
            const $configChangeInput = $(this).find(`input[id="config_change_${screenNo}"]`);
            if ($configChangeInput.length > 0) {
                problematicScreens.push(screenNo);
            }
        });
        return problematicScreens;
    }
});
