$(document).ready(function() {
    let hasDateErrors = false;
    let hasMotifErrors = false;

    function updateInputFields() {
        const startDate = $('#start_date_input').val();
        $('.start-date-posting-screens').text(startDate);
        $('input[name="posting[start]"]').val(startDate);
    }

    $('#start_date_input').on('input', function() {
        updateInputFields();
        dateValidationCheck();
    });

    if ($('#start_date_input').val()) {
        updateInputFields();
        dateValidationCheck();
    }

    function dateValidationCheck() {
        const selectedStartDate = parseDate($('#start_date_input').val());
        if (!selectedStartDate) return;

        hasDateErrors = false;
        const $warningList = $('#date_error .warning-screen-list').empty();
        let errorCount = 0;

        $('tr[data-controller="highlight screen-group-select"]').each(function () {
            const $row = $(this);
            const screenNo = $row.find('td[data-column="no"]').text().trim();
            const screenStart = parseDate($row.find('input[name="original_start"]').val());
            const screenStop = parseDate($row.find('td[data-column="stop"]').text().trim());

            if (!screenStart || !screenStop) return;

            if (selectedStartDate < screenStart || selectedStartDate > screenStop) {
                hasDateErrors = true;
                $row.find('.start-date-posting-screens').css('color', 'red');
                const screenStartFormatted = formatDate(screenStart);
                const screenStopFormatted = formatDate(screenStop);

                if (errorCount < 3) {
                    $warningList.append(`<li>Obrazovka č.: ${screenNo}, období pronájmu: ${screenStartFormatted} - ${screenStopFormatted}</li>`);
                }
                errorCount++;
            } else {
                $row.find('.start-date-posting-screens').css('color', '');
            }
        });

        if (errorCount > 3) {
            $warningList.append(`<li>A další ${errorCount - 3} plochy</li>`);
        }

        updateErrorDisplay();
    }

    function motifValidationCheck() {
        const missingMotifs = JSON.parse($('#missing_motifs').val() || '[]') || [];
        const $warningList = $('#motif_error .warning-screen-list').empty();
        const $motifErrorDiv = $('#motif_error').addClass('d-none');

        hasMotifErrors = missingMotifs.some(screenNo => {
            const inputField = $(`td[data-column="no"]:contains(${screenNo})`).siblings().find('input[data-column="motif"]');
            return inputField.val().trim() === '';
        });

        if (hasMotifErrors) {
            $('.warning-message', $motifErrorDiv).text('Následující obrazovky nemají motiv:');
            missingMotifs.forEach((screenNo, index) => {
                if (index < 3) {
                    $warningList.append(`<li>Obrazovka č.: ${screenNo}</li>`);
                }
            });

            if (missingMotifs.length > 3) {
                $warningList.append(`<li>A další ${missingMotifs.length - 3} plochy</li>`);
            }
        }

        updateErrorDisplay();
    }

    motifValidationCheck()

    function updateErrorDisplay() {
        hasDateErrors ? $('#date_error').removeClass('d-none') : $('#date_error').addClass('d-none');
        hasMotifErrors ? $('#motif_error').removeClass('d-none') : $('#motif_error').addClass('d-none');
        $("input[name='commit']").prop('disabled', hasDateErrors || hasMotifErrors);
    }

    function parseDate(dateString) {
        const parts = dateString.split('.');
        if (parts.length < 3) return null;
        const [day, month, year] = parts.map(part => parseInt(part.trim(), 10));
        return new Date(year, month - 1, day);
    }

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}. ${month}. ${year}`;
    }

    function toggleImportButton() {
        let fileSelected = $("#posting_file_import_motives").val() !== "";
        $("input[name='import_button']").prop("disabled", !fileSelected);
    }

    toggleImportButton();

    $(document).on('click', '#date_error .close', function() {
        $('#date_error').addClass('d-none');
    });

    $(document).on('click', '#motif_error .close', function() {
        $('#motif_error').addClass('d-none');
    });

    $('input[data-column="motif"]').on('input', function() {
        motifValidationCheck();
    });

    $(document).on("change", "#posting_file_import_motives", function() {
        toggleImportButton();
    });
});
