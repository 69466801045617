$(document).ready(function() {
    function applyRowColors() {
        if (window.location.pathname.startsWith("/campaigns/order") || window.location.pathname.startsWith("/postings")) {
            $(".listing-item").each(function() {
                const rowColor = $(this).find(".row-color").val();
                if (rowColor) {
                    $(this).addClass(rowColor);
                }
            });
        }
    }

    applyRowColors();

    $(document).on("ajaxComplete", function() {
        applyRowColors();
    });
});
